import DownloadIcon from '@mui/icons-material/Download';
import { Icon } from '@mui/material';
import { ReactAdminEditedFile } from '../../../../api/transformHooks/fileUpload';
import { getItemsByTypeFromAttachments } from '../../../../utils/getItemsByTypeFromAttachments';
import { PostAttachmentGroupMedia, PostAttachmentPdf } from './styled';

type AttachmentsPreviewProps = {
  attachments: ReactAdminEditedFile[] | undefined;
};
export const AttachmentsPreview = ({ attachments }: AttachmentsPreviewProps) => {
  if (!Array.isArray(attachments)) {
    return null;
  }

  const { images, videos, pdfs } = getItemsByTypeFromAttachments(attachments);

  return (
    <>
      {/* Single video */}
      {videos.length === 1 && images.length === 0 && (
        <div>
          <video src={videos[0].src} controls width="100%">
            <track kind="captions" />
          </video>
        </div>
      )}

      {/* Single image */}
      {images.length === 1 && videos.length === 0 && (
        <div>
          <img src={images[0].src} alt="attachment" width="100%" />
        </div>
      )}

      {/* Group of images */}
      {images.length >= 2 && videos.length === 0 && (
        <PostAttachmentGroupMedia>
          {images.map((image) => (
            <img
              src={image.src}
              alt="attachment"
              width="70%"
              key={image.src}
              style={{ pointerEvents: 'none' }}
            />
          ))}
        </PostAttachmentGroupMedia>
      )}

      {/* Group of images and video*/}
      {images.length > 0 && videos.length > 0 && (
        <PostAttachmentGroupMedia>
          <video src={videos[0].src} controls width="70%">
            <track kind="captions" />
          </video>
          {images.map((image) => (
            <img src={image.src} alt="attachment" width="70%" key={image.src} />
          ))}
        </PostAttachmentGroupMedia>
      )}

      {/* Pdf */}
      {pdfs.length === 1 && (
        <PostAttachmentPdf>
          <p>{pdfs[0].title}</p>
          <Icon component={DownloadIcon} />
        </PostAttachmentPdf>
      )}
    </>
  );
};
