import { useCallback, useMemo, useRef, useState } from 'react';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import useLocalization from '@sendbird/uikit-react/hooks/useLocalization';
import { useGetAllUsers } from '../../../../hooks/useGetAllUsers';
import { useFetchBannedChannelUsers } from '../../../../api/hooks/sendbird/useFetchBannedChannelUsers';
import { ChannelUserCard } from '../ChannelUserCard';
import { UserDto } from '../../../../api/customEndpoints/fetchAllUsers';
import { ChannelUserCardCheckbox } from '../ChannelUserCardCheckbox';
import { SearchTextInput } from '../SearchTextInput';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { UserList } from '../UserList';
import { useTranslate } from 'react-admin';

type UserId = string;
export interface InviteUsersModalProps {
  open: boolean;
  onCancel(): void;
  onSubmit(userIds: UserId[]): void;
}

export function InviteUsersModal({ onCancel, onSubmit, open }: InviteUsersModalProps) {
  const translate = useTranslate();
  const { data: growUsers } = useGetAllUsers();
  const { data: bannedUsers } = useFetchBannedChannelUsers();
  const { channel, overrideInviteUser } = useChannelSettingsContext();
  const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const bannedUserIds = useMemo(() => {
    return new Set(bannedUsers?.map((bu) => bu.userId.toString()));
  }, [bannedUsers]);

  const channelMemberIds = useMemo(() => {
    return new Set(channel?.members.map((m) => m.userId.toString()));
  }, [channel?.members]);

  // Filter out growUsers who are either banned or members of the channel
  const users = useMemo(() => {
    return (growUsers ?? []).filter(
      (u) => !bannedUserIds.has(String(u.id)) && !channelMemberIds.has(String(u.id)),
    );
  }, [growUsers, bannedUserIds, channelMemberIds]);

  const { stringSet } = useLocalization();

  // TODO: show some pending UI (such as via a useMutation hook)
  const onInviteUsers = useCallback(async () => {
    const userIdsToInvite = selectedUsers.map((u) => `${u.id}`);

    if (channel && typeof overrideInviteUser === 'function') {
      overrideInviteUser({ users: userIdsToInvite, onClose: onCancel, channel });
    } else {
      await channel?.inviteWithUserIds(userIdsToInvite);
      onSubmit(userIdsToInvite);
    }
  }, [onSubmit, selectedUsers, channel, overrideInviteUser, onCancel]);

  const containerRef = useRef(null);

  return (
    <Dialog
      scroll="paper"
      open={open}
      onClose={onCancel}
      onSubmit={onInviteUsers}
      fullWidth
    >
      <DialogTitle sx={{ paddingX: 2, paddingY: 1 }}>
        {translate('chat.inviteUsersModalTitle')}
        <SearchTextInput
          label="Search for new members"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }} ref={containerRef}>
        <UserList
          searchTerm={searchTerm}
          userIdsFilter={users.map((u) => u.id)}
          containerRef={containerRef}
          renderUser={(u) => {
            return (
              <ChannelUserCard
                user={u}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                right={<ChannelUserCardCheckbox user={u} selectedUsers={selectedUsers} />}
              />
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          {stringSet.BUTTON__CANCEL}
        </Button>
        <Button variant="contained" onClick={onInviteUsers}>
          {stringSet.BUTTON__INVITE}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
