import DeleteIcon from '@mui/icons-material/Delete';
import ReplyIcon from '@mui/icons-material/Reply';
import { Box, styled, Typography } from '@mui/material';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { MessageMenu, MessageMenuProps } from '@sendbird/uikit-react/ui/MessageMenu';
import { useTranslate } from 'react-admin';
import { ForwardMenuItem } from './ForwardMenuItem';
import { PinUnpinMenuItem } from './PinUnpinMenuItem';

export const MessageCustomMenu: React.FC<MessageMenuProps> = (menuProps) => {
  const translate = useTranslate();
  const { currentChannel } = useGroupChannelContext();

  return (
    <MessageMenu
      {...menuProps}
      renderMenuItems={(props) => {
        const { message, channel } = menuProps;
        const { DeleteMenuItem, ReplyMenuItem } = props.items;
        const isPinned = channel?.pinnedMessageIds.includes(message.messageId);

        return (
          <>
            <ReplyMenuItem>
              <MenuItemBoxContainer>
                <ReplyIcon />
                <Typography variant="subtitle1">{translate('chat.reply')}</Typography>
              </MenuItemBoxContainer>
            </ReplyMenuItem>

            <ForwardMenuItem message={message} />

            {/* @ts-expect-error - types are wrong in sendbird for this one, there is a isSuper property*/}
            {channel && !channel.isSuper && currentChannel?.myRole === 'operator' && (
              <PinUnpinMenuItem channel={channel} message={message} isPinned={isPinned} />
            )}

            <DeleteMenuItem>
              <MenuItemBoxContainer>
                <DeleteIcon />
                <Typography variant="subtitle1">{translate('chat.delete')}</Typography>
              </MenuItemBoxContainer>
            </DeleteMenuItem>
          </>
        );
      }}
    />
  );
};

export const MenuItemBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));
