import ShareIcon from '@mui/icons-material/Share';
import { Typography } from '@mui/material';
import { MenuItem, useMessageMenuContext } from '@sendbird/uikit-react/ui/MessageMenu';
import { useTranslate } from 'react-admin';
import { SendableMessage, useForwardMessageContext } from '../../ForwardMessageProvider';
import { MenuItemBoxContainer } from './MessageCustomMenu';

type ForwardMenuItemProps = {
  message: SendableMessage;
};
export const ForwardMenuItem: React.FC<ForwardMenuItemProps> = ({ message }) => {
  const translate = useTranslate();
  const menuContext = useMessageMenuContext();
  const forwardMessageContext = useForwardMessageContext();

  const handleOnClick = () => {
    forwardMessageContext.setMessage(message);
    forwardMessageContext.setShowModal(true);
    menuContext.hideMenu();
  };

  return (
    <>
      <MenuItem onClick={handleOnClick}>
        <MenuItemBoxContainer>
          <ShareIcon />
          <Typography variant="subtitle1">{translate('chat.forward')}</Typography>
        </MenuItemBoxContainer>
      </MenuItem>
    </>
  );
};
