import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import type { Member } from '@sendbird/chat/groupChannel';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import useLocalization from '@sendbird/uikit-react/hooks/useLocalization';
import Modal from '@sendbird/uikit-react/ui/Modal';
import UserListItem, { UserListItemProps } from '@sendbird/uikit-react/ui/UserListItem';
import UserListItemMenu from '@sendbird/uikit-react/ui/UserListItemMenu';
import React, { ReactElement, ReactNode } from 'react';
import { useTranslate } from 'react-admin';
import { useFetchChannelMembers } from '../../../../api/hooks/sendbird/useFetchChannelMembers';
import { useRemoveUser } from '../../../../api/hooks/sendbird/useRemoveUser';
import { CenteredCircularProgress } from '../../../custom/CenteredCircularProgress';

export interface MembersModalProps {
  onCancel(): void;
  renderUserListItem?: (props: UserListItemProps & { index: number }) => ReactNode;
}

export function MembersModal({
  onCancel,
  renderUserListItem = (props) => <UserListItem {...props} />,
}: MembersModalProps): ReactElement {
  const { channel } = useChannelSettingsContext();
  const { stringSet } = useLocalization();
  const translate = useTranslate();

  const { data: members, refetch: refetchMembers, isPending } = useFetchChannelMembers();
  const { mutate: removeUser } = useRemoveUser({
    onSuccess: () => refetchMembers(),
  });

  return (
    <div>
      <Modal
        isFullScreenOnMobile
        hideFooter
        onClose={() => onCancel()}
        onSubmit={() => void 0}
        titleText={stringSet.CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS}
      >
        <div className="sendbird-more-members__popup-scroll">
          {isPending ? (
            <CenteredCircularProgress />
          ) : (
            <>
              {(members ?? []).map((member: Member, index) => (
                <React.Fragment key={member.userId}>
                  {renderUserListItem({
                    // NOTE: This `index` is used to display the current user's user item at the top when customizing externally.
                    index,
                    user: member,
                    channel: channel ?? undefined,
                    renderListItemMenu: (props) => (
                      <UserListItemMenu
                        {...props}
                        onToggleOperatorState={() => refetchMembers()}
                        onToggleMuteState={() => refetchMembers()}
                        onToggleBanState={() => refetchMembers()}
                        renderMenuItems={({ items }) => {
                          const {
                            OperatorToggleMenuItem,
                            MuteToggleMenuItem,
                            BanToggleMenuItem,
                          } = items;

                          return (
                            <>
                              <OperatorToggleMenuItem />
                              <MuteToggleMenuItem />
                              <BanToggleMenuItem />

                              {channel?.myRole === 'operator' && (
                                <MenuItem
                                  disableRipple
                                  onClick={() =>
                                    removeUser({ id: member.userId, channel })
                                  }
                                >
                                  <Typography variant="subtitle2" fontFamily="Roboto">
                                    {translate('chat.kick')}
                                  </Typography>
                                </MenuItem>
                              )}
                            </>
                          );
                        }}
                      />
                    ),
                  })}
                </React.Fragment>
              ))}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
