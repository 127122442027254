import '@sendbird/uikit-react/dist/index.css';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import { FC } from 'react';
import { AdminContext, AdminProps } from 'react-admin';
import { useFetchSendBirdAppId } from '../api/hooks/useFetchSendBirdAppId';
import { UserRoleProvider } from '../auth/UserRoleProvider';
import { AdminResources } from '../components/admin/AdminResources';
import { sendBirdColorSet } from '../components/admin/Groups/colorSet';
import { useTenantId } from '../components/OrganizationGuard';
import { useGetCurrentUser } from '../hooks/useGetCurrentUser';
import { useInitMonitoring } from '../hooks/useInitMonitoring';
import { useUserSessionTracking } from '../utils/monitoringIdentify';

export const Admin: FC<AdminProps> = (props) => {
  const { data: user } = useGetCurrentUser();
  const { data: sendBirdApp } = useFetchSendBirdAppId();
  const tenantId = useTenantId();

  useInitMonitoring();
  useUserSessionTracking(user, tenantId);

  return (
    <AdminContext {...props}>
      <UserRoleProvider>
        <SendbirdProvider
          appId={sendBirdApp?.appId ?? ''}
          userId={user?.id.toString() ?? ''}
          colorSet={sendBirdColorSet}
        >
          <AdminResources {...props} />
        </SendbirdProvider>
      </UserRoleProvider>
    </AdminContext>
  );
};
