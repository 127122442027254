import { HttpClient } from '../httpClient';

type CohortMembership = {
  userId: number;
  cohortId: number;
};

type RequestSchema = {
  data: CohortMembership[];
};

export const removeCohortMembers =
  (httpClient: HttpClient) => async (request: RequestSchema) => {
    await httpClient.api(`/admin/cohortMemberships`, {
      method: 'DELETE',
      body: JSON.stringify(request),
    });
  };

export const addCohortMembers =
  (httpClient: HttpClient) => async (request: RequestSchema) => {
    await httpClient.api(`/admin/cohortMemberships`, {
      method: 'POST',
      body: JSON.stringify(request),
    });
  };
