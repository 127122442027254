import { ResourcesValue } from '../../components/admin/AdminResources';
import { getItemsByTypeFromAttachments } from '../../utils/getItemsByTypeFromAttachments';
import { LifecycleHooks } from '../addLifecycleHooks';
import { AdminDataProvider } from '../adminDataProvider';
import { handleUpload } from './fileUpload';

/**
 * Lifecycle hooks for the data provider to transform post data before saving. Files are uploaded to the file host and
 * @see https://marmelab.com/react-admin/withLifecycleCallbacks.html#beforesave
 */
export const postCreateDataTranformHook: LifecycleHooks[] = [
  {
    resource: ResourcesValue.POSTS,
    beforeSave: async (params, dataProvider) => {
      const { attachments, options: rawOptions, pdf: pollPdf, ...rest } = params;

      let images: string[] = [];
      let videos: any = [];
      let pdf: { url: string; name: string } | undefined;
      let options: string[] | undefined;

      const publishingDate = new Date().toISOString();

      try {
        // Transformation for poll posts
        if (rawOptions) {
          options = rawOptions.map((item: { text: string }) => item.text);

          const uploaded = await handleUpload(
            pollPdf,
            'public',
            dataProvider as AdminDataProvider,
          );

          if (uploaded) {
            pdf = {
              name: uploaded.title,
              url: uploaded.src,
            };
          }
        }

        // For Posts and recognition posts
        if (attachments) {
          const {
            images: imageFiles,
            videos: videoFiles,
            pdfs: pdfFiles,
          } = getItemsByTypeFromAttachments(attachments);

          if (imageFiles.length > 0) {
            const uploadedImages = await Promise.all(
              imageFiles.map(async (imageFile) => {
                return await handleUpload(
                  imageFile,
                  'images',
                  dataProvider as AdminDataProvider,
                );
              }),
            );

            images = [...uploadedImages.map((image) => image.src)];
          }

          if (videoFiles.length) {
            const uploadedVideos = await Promise.all(
              videoFiles.map(async (videoFile) => {
                return await handleUpload(
                  videoFile,
                  'public',
                  dataProvider as AdminDataProvider,
                );
              }),
            );
            videos = [...uploadedVideos.map((video) => video.src)];
          }

          if (pdfFiles.length) {
            const uploadedPdfs = await Promise.all(
              pdfFiles.map(async (pdfFile) => {
                return await handleUpload(
                  pdfFile,
                  'public',
                  dataProvider as AdminDataProvider,
                );
              }),
            );
            const results = [
              ...uploadedPdfs.map((pdf) => ({
                url: pdf.src,
                name: pdf.title,
              })),
            ];

            pdf = results[0];
          }
        }
      } catch (error) {
        // Throw a notify error here
      }

      return { ...rest, options, images, videos, publishingDate, pdf };
    },
  },
];
