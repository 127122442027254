import { Button, Link, Datagrid, TextField, useRecordContext } from 'react-admin';
import { MoonstarList } from '../Moonstar/MoonstarList';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAddOutlined';
import { MoonstarDatagridBulkActions } from '../Moonstar/MoonstarDatagridBulkActions';
import { Stack } from '@mui/material';
import { shrinkLastColumn } from '../../../utils/shrinkLastColumn';

export const CohortList = () => (
  <MoonstarList>
    <Datagrid bulkActionButtons={<MoonstarDatagridBulkActions />} sx={shrinkLastColumn}>
      <TextField source="name" />
      <CohortActions />
    </Datagrid>
  </MoonstarList>
);

const CohortActions = () => {
  const record = useRecordContext();

  return (
    <Stack
      direction="row"
      spacing={1}
      onClick={(e) => {
        // Fixes `Button` propagating the link up to the table and navigating twice
        e.stopPropagation();
      }}
    >
      <Button
        component={Link}
        to={`/cohorts/${record?.id}/members`}
        startIcon={<GroupIcon />}
        label="Members"
      />
      <Button
        component={Link}
        to={`/cohorts/${record?.id}/members/add`}
        startIcon={<GroupAddIcon />}
        label="Add members"
      />
    </Stack>
  );
};
