import PushPinIcon from '@mui/icons-material/PushPin';
import { Alert, Typography } from '@mui/material';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { FileMessage, MultipleFilesMessage, UserMessage } from '@sendbird/chat/message';
import { OpenChannel } from '@sendbird/chat/openChannel';
import { MenuItem, useMessageMenuContext } from '@sendbird/uikit-react/ui/MessageMenu';
import { useNotify, useTranslate } from 'react-admin';
import { usePinUnpinMessage } from '../../../../../api/hooks/sendbird/usePinUnpinMessage';
import { SendableMessage } from '../../ForwardMessageProvider';
import { MenuItemBoxContainer } from './MessageCustomMenu';

// https://sendbird.com/docs/chat/sdk/v4/android/message/managing-pinned-messages-in-group-channels/pin-a-message#-3-limitations
export const MAX_PINNED_MESSAGES = 10;

type PinUnpinMenuItemProps = {
  channel: GroupChannel | OpenChannel;
  message: SendableMessage;
  isPinned: boolean | undefined;
};

export const PinUnpinMenuItem: React.FC<PinUnpinMenuItemProps> = ({
  channel,
  message,
  isPinned,
}) => {
  const notify = useNotify();
  const translate = useTranslate();
  const menuContext = useMessageMenuContext();
  const { mutate, isPending } = usePinUnpinMessage();

  const onPinOrUnPin = async ({
    channel,
    message,
    isPinned,
  }: {
    channel: GroupChannel | OpenChannel | null;
    message: UserMessage | FileMessage | MultipleFilesMessage;
    isPinned: boolean;
  }) => {
    if (!channel) return;
    if (channel.pinnedMessageIds.length >= MAX_PINNED_MESSAGES && !isPinned) {
      notify(
        <Alert severity="error">
          {translate('chat.pinMessageMax', {
            maxPinnedMessages: MAX_PINNED_MESSAGES,
          })}
        </Alert>,
        {
          autoHideDuration: 5000,
        },
      );
      return;
    }

    mutate({ channel, message, isPinned });
    menuContext.hideMenu();
  };

  return (
    <MenuItem
      onClick={() => onPinOrUnPin({ channel, message, isPinned: !!isPinned })}
      disabled={isPending}
    >
      <MenuItemBoxContainer>
        <PushPinIcon />
        <Typography variant="subtitle1">
          {translate(isPinned ? 'chat.unpin' : 'chat.pin')}
        </Typography>
      </MenuItemBoxContainer>
    </MenuItem>
  );
};
