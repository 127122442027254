import { Avatar, Box, CircularProgress, Popover, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { useState } from 'react';
import { resolveBrowserLocale, useTranslate } from 'react-admin';
import {
  PinnedMessage,
  useFetchPinnedMessages,
} from '../../../../../api/hooks/sendbird/useFetchPinnedMessages';
import { sendBirdColorSet } from '../../colorSet';

type PinnedMessagesPopoverProps = {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
};

export const PinnedMessagesPopover = ({
  open,
  anchorEl,
  handleClose,
}: PinnedMessagesPopoverProps) => {
  const [selectedMessageId, setSelectedMessageId] = useState<number>();
  const { data: pinnedMessages, isError, isPending } = useFetchPinnedMessages();
  const locale = resolveBrowserLocale();
  const translate = useTranslate();
  const { scrollToMessage } = useGroupChannelContext();
  const theme = useTheme();

  const handleOnPinnedClick = (message: PinnedMessage) => {
    setSelectedMessageId(message.id);
    scrollToMessage(message.date, message.id);
    handleClose();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          minWidth: 300,
          alignContent: 'baseline',
          flexDirection: 'column',
        }}
      >
        {isPending && (
          <Box
            sx={{ padding: theme.spacing(2), display: 'flex', justifyContent: 'center' }}
          >
            <CircularProgress color="primary" />
          </Box>
        )}

        {isError && (
          <Typography sx={{ padding: theme.spacing(4) }}>
            {translate('chat.pinnedMessagesCouldNotBeFetched')}
          </Typography>
        )}

        {!pinnedMessages?.length && !isError && (
          <Typography sx={{ padding: theme.spacing(4) }}>
            {translate('chat.pinnedMessagesNotFound')}
          </Typography>
        )}

        {pinnedMessages &&
          !!pinnedMessages.length &&
          pinnedMessages.map((message) => (
            <Box
              sx={{
                padding: theme.spacing(2),
                cursor: 'pointer',
                ':hover': {
                  backgroundColor:
                    message.id === selectedMessageId
                      ? sendBirdColorSet['--sendbird-light-primary-200']
                      : '#f0f0f0',
                },
                display: 'flex',
                gap: theme.spacing(2),
                backgroundColor:
                  message.id === selectedMessageId
                    ? sendBirdColorSet['--sendbird-light-primary-100']
                    : '#fff',
                borderLeft:
                  message.id === selectedMessageId
                    ? `4px solid ${sendBirdColorSet['--sendbird-light-primary-400']}`
                    : '4px solid #f0f0f0',
              }}
              key={message.id}
              onClick={() => handleOnPinnedClick(message)}
            >
              <Avatar
                sx={{ width: 56, height: 56 }}
                src={message.avatarUrl}
                alt="avatar image"
              />

              <div>
                <Typography sx={{ fontWeight: 'bold' }}>{message.displayName}</Typography>
                <Typography>{message.content}</Typography>
              </div>
              <Typography sx={{ marginLeft: 'auto' }}>
                {new Date(message.date).toLocaleDateString(locale, {
                  day: 'numeric',
                  month: 'short',
                })}
              </Typography>
            </Box>
          ))}
      </Box>
    </Popover>
  );
};
