import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useForwardMessage } from '../../../../../api/hooks/sendbird/useForwardMessage';
import { useForwardMessageContext } from '../../ForwardMessageProvider';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useGetCurrentUser } from '../../../../../hooks/useGetCurrentUser';
import { getChatChannelName } from '../../../../../utils/getChatChannelName';

export const ForwardMessageModal = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const shareMessageContext = useForwardMessageContext();
  const [selectedChannelsUrls, setSelectedChannelsUrls] = useState<string[]>([]);
  const { message, groupChannels } = shareMessageContext;
  const { mutate: forwardMessage, isPending } = useForwardMessage();
  const { data: currentUser } = useGetCurrentUser();

  const handleClose = () => {
    shareMessageContext.setShowModal(false);
  };

  const handleToggle = (url: string) => {
    if (selectedChannelsUrls.includes(url)) {
      setSelectedChannelsUrls(
        selectedChannelsUrls.filter((selectedUrl) => selectedUrl !== url),
      );
    } else {
      setSelectedChannelsUrls([...selectedChannelsUrls, url]);
    }
  };

  const handleShare = async () => {
    const groupsToShare = groupChannels?.filter((channel) =>
      selectedChannelsUrls.includes(channel.url),
    );
    if (!groupsToShare?.length || !message) return;

    forwardMessage(
      { message, channels: groupsToShare },
      {
        onSuccess: () => {
          setSelectedChannelsUrls([]);
          handleClose();
          notify(<Alert severity="success">{translate('chat.messageForwarded')}</Alert>, {
            autoHideDuration: 5000,
          });
        },
      },
    );
  };

  return (
    <Dialog
      fullWidth
      open={shareMessageContext.showModal}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{translate('chat.messageForwardTitle')}</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        {groupChannels?.map((channel) => {
          if (channel.url === message?.channelUrl) {
            return null;
          }
          return (
            <Card key={channel.url} square variant="outlined" elevation={0}>
              <CardActionArea
                onClick={() => handleToggle(channel.url)}
                disabled={isPending}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    height: 48,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexGrow: 1,
                      alignItems: 'center',
                      gap: 2,
                      maxWidth: '100%',
                    }}
                  >
                    <Avatar alt={`Channel: ${channel.name}`} src={channel.coverUrl} />

                    <Typography
                      variant="subtitle1"
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {currentUser && getChatChannelName(channel, currentUser)}{' '}
                    </Typography>

                    <Checkbox
                      edge="end"
                      checked={selectedChannelsUrls.includes(channel.url)}
                      tabIndex={-1}
                      disableRipple
                      style={{ marginLeft: 'auto' }}
                    />
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} disabled={isPending}>
          {translate('chat.cancel')}
        </Button>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Button
            variant="contained"
            onClick={handleShare}
            autoFocus
            disabled={!selectedChannelsUrls.length || isPending}
          >
            {translate('chat.forward')}{' '}
            {selectedChannelsUrls.length > 0 ? `(${selectedChannelsUrls.length})` : ''}
          </Button>
          {isPending && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};
