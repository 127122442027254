import { GroupChannel, GroupChannelCreateParams } from '@sendbird/chat/groupChannel';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useNotify, useTranslate } from 'react-admin';
import { UserDto } from '../../customEndpoints/fetchAllUsers';
import { useCreateChannelContext } from '@sendbird/uikit-react/CreateChannel/context';
import { useCallback, useMemo } from 'react';
import { useGetCurrentUser } from '../../../hooks/useGetCurrentUser';
import { CHANNEL_TYPE } from '../../../components/admin/Chat/GroupCustomChannelList/types';
import { User } from '../../../components/admin/User/types';

type CreateChannelParams = {
  type: CHANNEL_TYPE;
  currentUser: User;
  selectedUsers: UserDto[];
  createChannelFn: (params: GroupChannelCreateParams) => Promise<GroupChannel>;
};

const createChannel = async ({
  type,
  currentUser,
  selectedUsers,
  createChannelFn,
}: CreateChannelParams) => {
  const invitedUserIds = selectedUsers.map((su) => su.id.toString());
  const isDistinct = invitedUserIds.length === 1;

  const params: GroupChannelCreateParams = {
    invitedUserIds,
    isDistinct,
    isSuper: type === CHANNEL_TYPE.SUPERGROUP,
    operatorUserIds: [currentUser.id.toString()],
    name: '',
    coverUrl: '',
  };

  return await createChannelFn(params);
};

export const useCreateChannel = (
  options?: UseMutationOptions<GroupChannel | undefined, Error, CreateChannelParams>,
) => {
  const notify = useNotify();
  const translate = useTranslate();
  const { sdk, type, createChannel: createChannelFn } = useCreateChannelContext();
  const { data: currentUser } = useGetCurrentUser();

  const isSdkReady = useMemo(() => sdk && Object.keys(sdk).length > 0, [sdk]);

  const createGroupFailedNotify = useCallback(
    () => notify(translate('chat.groupCreationError'), { type: 'error' }),
    [notify, translate],
  );

  return useMutation<GroupChannel | undefined, Error, CreateChannelParams>({
    mutationKey: ['create-channel'],
    mutationFn: async ({ selectedUsers }) => {
      if (!isSdkReady || !currentUser) {
        createGroupFailedNotify();
        return undefined;
      }

      return await createChannel({
        type,
        currentUser,
        selectedUsers,
        createChannelFn,
      });
    },
    onError: createGroupFailedNotify,
    ...options,
  });
};
