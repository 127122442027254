import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useNotify, useTranslate } from 'react-admin';

type RemoveUserParams = {
  id: string;
  channel: GroupChannel;
};

const removeUser = async ({ id, channel }: RemoveUserParams) => {
  return await channel?.banUserWithUserId(id, 0);
};

export const useRemoveUser = (
  options?: UseMutationOptions<unknown, Error, RemoveUserParams>,
) => {
  const notify = useNotify();
  const translate = useTranslate();

  return useMutation({
    mutationKey: ['remove-user'],
    mutationFn: removeUser,
    ...options,
    onError: () => {
      notify(translate('chat.removeUserError'), {
        autoHideDuration: 5000,
      });
    },
  });
};
