import {
  DataProvider,
  Identifier,
  RaRecord,
  ResourceCallbacks,
  withLifecycleCallbacks,
} from 'react-admin';
import { AdminDataProvider } from './adminDataProvider';
import { fileUploadHooks } from './transformHooks/fileUpload';
import { postCreateDataTranformHook } from './transformHooks/postCreationDataTransform';
import { badgesDataTransform } from './transformHooks/badgesDataTransform';

// Adds support for custom `DataProvider` types by remapping `DataProvider` to `AdminDataProvider`
export type LifecycleHooks<T extends RaRecord<Identifier> = any> = {
  [K in keyof ResourceCallbacks<T>]: ResourceCallbacks<T>[K] extends
    | ((params: infer TRecord, dataProvider: DataProvider) => infer TResult)
    | undefined
    ? ((params: TRecord, dataProvider: AdminDataProvider) => TResult) | undefined
    : ResourceCallbacks<T>[K];
};

export const addProviderLifecycleHooks = (provider: AdminDataProvider) =>
  withLifecycleCallbacks(provider, [
    ...fileUploadHooks,
    ...postCreateDataTranformHook,
    ...badgesDataTransform,
  ]);
