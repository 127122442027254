import { FC, useEffect, useMemo } from 'react';
import { useInput, useTranslate } from 'react-admin';
import { useFetchAllTopics } from '../../../api/hooks/useFetchAllTopics';
import { MuiAutocomplete } from '../../MuiAutocomplete';

export type TopicSelectorProps = {
  // eslint-disable-next-line no-unused-vars
  handleTopicChange: (typeId: number, topicId: number) => void;
  selectedTopic: number | undefined;
};

export const TopicSelector: FC<TopicSelectorProps> = (props) => {
  const topics = useFetchAllTopics();
  const { field } = useInput({
    source: 'topicId',
  });

  useEffect(() => {
    if (!topics.data) return;

    const firstChoice = topics.data[0];

    if (!props.selectedTopic && firstChoice) {
      field.onChange(firstChoice.id);
      props.handleTopicChange(firstChoice.formType.id, firstChoice.id);
    }
  }, [topics.data, props, field]);

  const selectedTopic = useMemo(() => {
    if (!topics.data || !props.selectedTopic) return undefined;
    const selected = topics.data.find((t) => t.id === props.selectedTopic);

    return selected;
  }, [props.selectedTopic, topics.data]);

  const t = useTranslate();

  return (
    <MuiAutocomplete
      style={{ maxWidth: '250px' }}
      required
      isPending={topics.isPending}
      isError={topics.isError}
      label={t('resources.topics.fields.displayName')}
      options={topics.data ?? []}
      getOptionLabel={(option) => option.displayName ?? ''}
      onChange={(_e, record) => {
        // We have to explicitly set the value of the `topicId` field here, since it's not part
        // of the react-admin inputs (it's Mui input), and the form value won't get updated otherwise
        field.onChange(record.id);

        // Handle topic change normally
        props.handleTopicChange(record.formType.id, record.id);
      }}
      value={selectedTopic}
    />
  );
};
