import InfoIcon from '@mui/icons-material/InfoOutlined';
import PushPinIcon from '@mui/icons-material/PushPinOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { GroupChannelProps } from '@sendbird/uikit-react/GroupChannel';
import {
  GroupChannelHeader,
  GroupChannelHeaderProps,
} from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import { useState } from 'react';
import { PinnedMessagesPopover } from './PinnedMessagesPopover/PinnedMessagesPopover';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';

type GroupCustomHeaderProps = Pick<
  GroupChannelProps,
  'onSearchClick' | 'onChatHeaderActionClick'
> &
  GroupChannelHeaderProps;

export const GroupCustomHeader = (props: GroupCustomHeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { currentChannel } = useGroupChannelContext();

  return (
    <GroupChannelHeader
      {...props}
      renderRight={() => (
        <>
          {!currentChannel?.isSuper && (
            <IconButton
              color="primary"
              aria-label="pinned messages"
              size="large"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <PushPinIcon />
            </IconButton>
          )}

          <IconButton
            color="primary"
            aria-label="search messages"
            size="large"
            onClick={props.onSearchClick}
          >
            <SearchIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="settings"
            size="large"
            onClick={props.onChatHeaderActionClick}
          >
            <InfoIcon />
          </IconButton>

          {!currentChannel?.isSuper && (
            <PinnedMessagesPopover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              handleClose={() => setAnchorEl(null)}
            />
          )}
        </>
      )}
    />
  );
};
