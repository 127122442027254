/* eslint-disable react/jsx-key */
import { AutocompleteArrayInput, DateInput, ReferenceArrayInput } from 'react-admin';

export const PostFilters = [
  <ReferenceArrayInput
    label="Topic"
    source="topicId"
    reference="topics"
    perPage={999}
    sort={{ field: 'displayName', order: 'ASC' }}
    alwaysOn
  >
    <AutocompleteArrayInput
      margin="none"
      optionText="displayName"
      filterToQuery={(searchText) => ({ displayName: `%${searchText}%` })}
    />
  </ReferenceArrayInput>,
  <DateInput source="createdAfter" label="Created after" />,
  <DateInput source="createdBefore" label="Created before" />,
];
