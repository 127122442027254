import { MessageSearch } from '@sendbird/uikit-react';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import {
  MessageSearchProvider,
  MessageSearchProviderProps,
} from '@sendbird/uikit-react/MessageSearch/context';

type GroupCustomMessageSearchProps = MessageSearchProviderProps & {
  onCloseClick: () => void;
};

export const GroupCustomMessageSearch = (props: GroupCustomMessageSearchProps) => {
  const context = useGroupChannelContext();

  return (
    <MessageSearchProvider {...props}>
      <MessageSearch
        channelUrl={props.channelUrl}
        onCloseClick={props.onCloseClick}
        onResultClick={(message) =>
          context.scrollToMessage(message.createdAt, message.messageId)
        }
      />
    </MessageSearchProvider>
  );
};
