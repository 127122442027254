import Forum from '@mui/icons-material/Forum';
import Leaderboard from '@mui/icons-material/Leaderboard';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Menu } from 'react-admin';
import { useDashboardVisibility } from '../hooks/useDashboardVisibility';
import { useFeatureFlags } from '../components/FeatureFlagGuard';
import { CustomResourcesValue } from '../components/admin/AdminResources';

export const SideMenuContent = () => {
  const { showDashboard } = useDashboardVisibility();
  const { data: featureFlags, isPending } = useFeatureFlags();

  if (isPending) {
    return null;
  }

  return (
    <Menu>
      {showDashboard && (
        <Menu.Item to="/" primaryText="Dashboard" leftIcon={<Leaderboard />} />
      )}
      {featureFlags?.withWebChat && (
        <Menu.Item to="/groups" primaryText="Groups" leftIcon={<Forum />} />
      )}
      {featureFlags?.withFullVersionWebChat && (
        <Menu.Item
          to={`/${CustomResourcesValue.GROUPS_CUSTOM}`}
          primaryText="Chat"
          leftIcon={<Forum />}
        />
      )}
      <Menu.ResourceItems />
    </Menu>
  );
};
