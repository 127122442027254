import { FC } from 'react';
import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../../../../api/adminDataProvider';
import { CustomBulkAction } from '../../../custom/CustomBulkAction';
import { ResourcesValue } from '../../AdminResources';
import { GroupAddOutlined } from '@mui/icons-material';

export const BulkAddCohortMembers: FC<{ cohortId: number }> = (props) => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  return (
    <CustomBulkAction
      resource={ResourcesValue.COHORT_MEMBERSHIPS}
      mutationFn={(userIds) =>
        dataProvider.addCohortMembers({
          data: userIds.map((userId) => ({
            cohortId: props.cohortId,
            userId,
          })),
        })
      }
      label="ra.action.add"
      buttonIcon={<GroupAddOutlined />}
      successMessage='moonstar.cohortMemberships.add.successMessage'
    />
  );
};
