import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { MAX_PINNED_MESSAGES } from '../../../components/admin/Chat/GroupCustomChannel/MessageCustomMenu/PinUnpinMenuItem';

export type PinnedMessage = {
  id: number;
  avatarUrl: string;
  content: string;
  displayName: string;
  date: number;
};

const fetchPinnedMessages = async (channel: GroupChannel | null) => {
  if (!channel) return [];

  const query = channel.createPinnedMessageListQuery({
    limit: MAX_PINNED_MESSAGES,
    includeMetaArray: true,
    includeReactions: true,
    includeParentMessageInfo: true,
    includeThreadInfo: true,
    includePollDetails: true,
  });

  const pinnedMsgs = await query.next();
  if (!pinnedMsgs) return [];

  return pinnedMsgs.map((pinnedMsg) => ({
    id: pinnedMsg.message?.messageId ?? 0,
    content: pinnedMsg.message?.message ?? '',
    date: pinnedMsg.message?.createdAt ?? 0,
    // @ts-expect-error message does have sender property
    avatarUrl: pinnedMsg.message.sender.plainProfileUrl ?? '',
    // @ts-expect-error message does have a sender and nickname property
    displayName: pinnedMsg.message.sender.nickname ?? '',
  }));
};

export const useFetchPinnedMessages = (options?: UseQueryOptions<PinnedMessage[]>) => {
  const { currentChannel } = useGroupChannelContext();

  return useQuery<PinnedMessage[]>({
    queryKey: ['pinned-messages', currentChannel?.url],
    queryFn: () => fetchPinnedMessages(currentChannel),
    ...options,
  });
};
